import React from "react";
import { Link } from "react-router-dom";
import Button from "./ui/Button";
import Input from "./ui/Input";
import CallToSubscribe from "./molecules/CallToSubscribe";

export default function Footer() {
  return (
    <div className="">
      <CallToSubscribe />
    </div>
  );
}
