import { useContext, useState } from "react";
import WheelComponent from "../atoms/WheelComponent";
import AppContext from "context/AppContext";
import Button from "components/ui/Button";
import Confetti from "react-confetti";
import { useTranslation } from "react-i18next";

export default function WheelPrize() {
  const segments = [
    "-10%",
    "-15%",
    "Gift",
    "-0%",
    "-5%",
    "-20%",
    "Gift",
    "-0%",
  ];
  const segColors = [
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#34A24F",
    "#F9AA1F",
    "#EC3F3F",
    "#FF9000",
  ];

  const { t } = useTranslation();
  const { trackAction } = useContext(AppContext);
  const onFinished = (winner: any) => {
    console.log(winner);
    trackAction("Spin");
    setTimeout(function () {
      setPrize(winner);
    }, 1000);
  };

  const [prize, setPrize] = useState<any>(null);
  const [acceptPrize, setAcceptPrize] = useState<any>(false);
  const { setShowWheelPrize } = useContext(AppContext);

  return (
    <>
      {prize ? (
        <>
          <div className="absolute top-0 left-0 z-[99]">
            <Confetti width={window.innerWidth} height={window.innerHeight} />
          </div>
          <div className="relative grid grid gap-6 p-6">
            <div className="grid text-center">
              <h1 className="text-3xl font-bold">{t("congrats")}</h1>
              <h3 className="text-sm pt-2">{t("congrats_msg")}</h3>
              <h1 className="font-extrabold text-7xl mt-10">{prize}</h1>
            </div>
            <div className="grid gap-2">
              <Button
                isAction
                className="mt-6"
                onClick={() => {
                  setAcceptPrize(true);
                  setShowWheelPrize(false);
                }}
              >
                {t("congrats_take")}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="relative grid gap-6 py-6 px-3">
          <button
            onClick={() => setShowWheelPrize(false)}
            className="absolute top-3 right-3"
          >
            <i className="fa fa-close text-2xl"></i>
          </button>
          <div className="grid">
            <div className="grid text-center">
              <h1 className="text-3xl font-bold">{t("spin_title")}</h1>
              <h3 className="text-sm pt-2">{t("spin_subTitle")}</h3>
            </div>
            <div className="mx-auto">
              <WheelComponent
                segments={segments}
                segColors={segColors}
                winningSegment="-15%"
                onFinished={(winner) => onFinished(winner)}
                primaryColor="black"
                contrastColor="white"
                buttonText={t("spin")}
                isOnlyOnce={false}
                size={window.innerWidth < 500 ? 120 : 200}
                upDuration={300}
                downDuration={3000}
                fontFamily="Arial"
              />
            </div>
            <div className="">
              {prize ? (
                <div className="grid gap-3">
                  <Button
                    isAction
                    onClick={() => {
                      setAcceptPrize(true);
                    }}
                  >
                    {/* <i className="fa fa-check mr-2"></i> */}({prize}){" "}
                    {t("congrats_take")}
                  </Button>
                </div>
              ) : (
                <div className="text-center font-semibold">
                  {t("spin_launch")}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
