import AppContext from "context/AppContext";
import AuthContext from "context/AuthContext";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";

export default function MobileMenu() {
  const { isMobileMenuOpen, setIsMobileMenuOpen } = useContext(AppContext);
  const { user, logout } = useContext(AuthContext);
  const { t } = useTranslation();

  const menu = [
    {
      name: t("menu.home"),
      icon: "home",
      link: "/",
      end: true,
    },
    {
      name: "Link 1",
      icon: "clock-rotate-left",
      link: "/auth/sign-up",
      end: false,
      isAction: true,
    },
    {
      name: "Link 2",
      icon: "clock-rotate-left",
      link: "/auth/sign-up",
      end: false,
      isAction: true,
    },
    {
      name: t("menu.about"),
      icon: "circle-info",
      link: "/about",
      end: false,
    },
    {
      name: t("menu.contact"),
      icon: "circle-info",
      link: "/contact",
      end: false,
    },
  ];

  return (
    <>
      {isMobileMenuOpen && (
        <div className="z-[99] absolute top-0 bottom-0 left-0 right-0 bg-black/50 backdrop-blur-sm duration-300">
          <div className="relative flex flex-col gap-3 h-full w-3/4 md:w-1/4 ml-auto bg-neutral2 shadow-lg p-10">
            <button
              onClick={() => setIsMobileMenuOpen(false)}
              className="absolute p-3 top-2 left-2"
            >
              <i className="fa fa-close"></i>
            </button>
            <Link to="/" className="relative flex items-center gap-3 pb-6">
              <img
                src={require("assets/images/bambinos-logo.png")}
                alt="logo"
                className="h-[70px] mx-auto pr-3"
              />
            </Link>
            <div className="grid gap-3 py-10">
              {menu.map((item: any, i: number) => {
                return (
                  <NavLink
                    to={item?.link}
                    className={`font-semibold hover:pl-2 duration-300 hover:text-primary`}
                  >
                    {item?.name}
                  </NavLink>
                );
              })}
            </div>
            <span className="mt-auto text-center text-sm font-semibold">Bambinos © {new Date().getFullYear()}</span>
          </div>
        </div>
      )}
    </>
  );
}
