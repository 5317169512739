import { FC, ReactElement } from "react";
import Button from "./Button";

interface ModalProps {
  open: boolean;
  onClose?: () => void;
  children: ReactElement;
}

export default function Modal(props: ModalProps): ReturnType<FC> {
  return (
    <>
      {props.open && (
        <div
          className={`${"z-[99] fixed top-0 bottom-0 left-0 right-0 bg-black/30 grid place-items-center rounded-2xl backdrop-blur-[5px]"}`}
        >
          <div className="grid place-items-center bg-white rounded-lg shadow-lg w-11/12 md:w-2/4 min-w-[300px]">
            {/* <div className="modal-head">
          <h1>Modal</h1>
        </div> */}
            <div className="p-3 md:p-6">{props.children}</div>
            {/* <div className="flex items-center justify-center gap-3 border-t border-primary w-full p-6">
              <Button
                isAction
                onClick={function (): void {
                  // something here ...
                  props.onClose();
                }}
              >
                Confirm
              </Button>
              <Button onClick={props.onClose}>Close</Button>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
}
