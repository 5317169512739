import { useState, useEffect, createContext, FC } from "react";
import { toast } from "react-toastify";

const AppContext = createContext<Partial<any>>({});
export default AppContext;

export const AppProvider = ({ children }: any) => {
  const [isSidebarOpen, setIsSidbarOpen] = useState<boolean>(true);
  const toggleSidebar = () => setIsSidbarOpen(!isSidebarOpen);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [data, setData] = useState(null);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState<boolean>(false);
  const [showWheelPrize, setShowWheelPrize] = useState<boolean>(true);

  async function trackAction(actionString: string) {
    try {
      await fetch("https://coala.ns.ma/landing", {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify({ action: actionString }),
      });
    } catch (error) {
      console.error(error);
    }
  }

  const notify = (message: string) => toast(message);

  useEffect(() => {
    fetch('https://raw.githubusercontent.com/ayoubelaamri/bambinos-publicData/refs/heads/main/data.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((jsonData) => {
        setData(jsonData);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  return (
    <AppContext.Provider
      value={{
        isSidebarOpen,
        toggleSidebar,
        isLoading,
        setIsLoading,
        data,
        isMobileMenuOpen,
        setIsMobileMenuOpen,
        isOrderModalOpen,
        setIsOrderModalOpen,
        showWheelPrize,
        setShowWheelPrize,

        trackAction,
        notify,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
