import { LanguageContext } from "context/LanguageContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

export default function CallToSubscribe({ forBlog = false }: any) {
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();
  return (
    <div className="w-full bg-gradient-to-br from-primary1 to-primary2 md:rounded-xl shadow-lg">
      <div className="grid place-items-center gap-6 md:flex md:items-center md:justify-between md:rounded-xl bg-gradient-to-r from-slate-800/70 to-slate-800/50 w-full mx-auto px-6 py-6 md:px-10 lg:px-20">
        <div className="grid text-neutral2">
          <h1 className="text-lg md:text-3xl font-bold md:font-semibold font-roboto">
            {t("subscribe_title")} <br />
          </h1>
          <span className="flex items-center text-sm font-semibold font-roboto">
            {t("subscribe_phrase")}
          </span>
        </div>
        <div className="flex items-center shadow-lg">
          <input
            type="text"
            name="email"
            placeholder="name@example.com"
            className={`px-3 py-2 ${
              language === "ar" ? "rounded-r-lg" : "rounded-l-lg"
            } w-full md:w-60`}
          />
          <button
            className={`px-3 py-2 ${
              language === "ar" ? "rounded-l-lg" : "rounded-r-lg"
            }  bg-secondary2 text-neutral font-semibold hover:bg-neutral-600 duration-75`}
          >
            <span className="hidden lg:block">{t("subscribe")}</span>
            <i className="fa fa-paper-plane block lg:hidden"></i>
          </button>
        </div>
      </div>
    </div>
  );
}
