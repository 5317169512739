import Input from "components/ui/Input";
import React, { useContext, useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "components/ui/Button";
import AppContext from "context/AppContext";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "context/LanguageContext";
import { useNavigate, useParams } from "react-router-dom";

interface IForm {
  name: string;
  email: string;
  phone: string;
  city: string;
  variant: string;
  message: string;
}

export default function Order2() {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const { data, isOrderModalOpen, setIsOrderModalOpen, trackAction } =
    useContext(AppContext);

  const [selectedVariant, setSelectedVariant] = useState<any>("M");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForm>();
  const onSubmit: SubmitHandler<IForm> = (formData) => {
    console.log(formData);
    setIsLoading(true);
    const product_name = data?.products.find(
      (x: any) => (x.id = params?.id)
    ).name;
    emailjs
      .send("service_2mvozk2", "template_5mul5fd", {
        name: formData?.name || "##unavailable",
        email: formData?.email || "##unavailable",
        phone: formData?.phone || "##unavailable",
        city: formData?.city || "##unavailable",
        variant: product_name || "##unavailable",
        message: formData?.message || "##unavailable",
      })
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
        },
        (error) => {
          console.log("FAILED...", error);
        }
      )
      .finally(() => {
        trackAction(`Send_order_product_${params?.id}`);
        setIsLoading(false);
        setIsOrderModalOpen(false);
        navigate("thank-you");
      });
  };

  useEffect(() => {
    emailjs.init({
      publicKey: "rSfNw5BuaOjhBjym9",
      // Do not allow headless browsers
      blockHeadless: true,
      blockList: {
        // Block the suspended emails
        // list: ["foo@emailjs.com", "bar@emailjs.com"],
        // The variable contains the email address
        watchVariable: "email",
      },
      limitRate: {
        // Set the limit rate for the application
        id: "app",
        // Allow 1 request per 10s
        throttle: 10000,
      },
    });
  }, []);

  return (
    <div className="relative flex flex-col gap-3 w-full h-[calc(100vh-15em)] p-6 overflow-y-auto">
      <button
        onClick={() => setIsOrderModalOpen(false)}
        className="absolute top-3 left-3 scale-[1.5]"
      >
        <i className="fa fa-close"></i>
      </button>
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="contactForm"
        className="w-full"
      >
        <img
          src={require("assets/images/bambinos-logo.png")}
          alt="logo"
          className="h-16 mx-auto"
        />
        <h1 className="font-bold text-xl flex items-center gap-2 justify-center my-6">
          <i
            className={`fa ${
              language === "ar" ? "fa-arrow-left-long" : "fa-arrow-right-long"
            } `}
          ></i>
          {t("order_now")}
        </h1>
        <div className="grid w-full gap-6">
          <div className="grid md:grid-cols-2 gap-3">
            <div className="relative">
              <input
                {...register("name", { required: "Name is required" })}
                aria-invalid={errors.name ? true : false}
                type="text"
                id="name"
                name="name"
                className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
              />
              {errors.name && (
                <p className="text-sm text-red-500" role="alert">
                  {errors.name?.message}
                </p>
              )}
              <label
                htmlFor="name"
                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
              >
                {t("name")}
              </label>
            </div>
            <div className="relative">
              <input
                {...register("email")}
                type="text"
                id="email"
                name="email"
                className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
              />
              <label
                htmlFor="email"
                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
              >
                {t("email")}
              </label>
            </div>
            <div className="relative">
              <input
                {...register("phone", { required: "Phone is required" })}
                aria-invalid={errors.phone ? true : false}
                type="text"
                id="phone"
                name="phone"
                className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
              />
              {errors.phone && (
                <p className="text-sm text-red-500" role="alert">
                  {errors.phone?.message}
                </p>
              )}
              <label
                htmlFor="phone"
                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
              >
                {t("phone")}
              </label>
            </div>
            <div className="relative">
              <input
                {...register("city", { required: "City is required" })}
                aria-invalid={errors.city ? true : false}
                type="text"
                id="city"
                name="city"
                className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
              />
              {errors.city && (
                <p className="text-sm text-red-500" role="alert">
                  {errors.city?.message}
                </p>
              )}
              <label
                htmlFor="city"
                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
              >
                {t("city")}
              </label>
            </div>
          </div>
          {/* <div className="grid gap-6">
            <div className="grid gap-2 my-auto mx-auto">
              <h3 className="text-sm font-semibold text-center">
                Choisir la variante :
              </h3>
              <div className="flex items-center gap-3">
                <button
                  type="button"
                  onClick={() => setSelectedVariant("M")}
                  className={`shadow-lg hover:shadow-primary border ${
                    selectedVariant === "M" && "border-primary"
                  }`}
                >
                  <img
                    src={require("assets/images/products/bureau1.jpg")}
                    alt=""
                    className="w-20"
                  />
                  <span className="text-xs">Garçons</span>
                </button>
                <button
                  type="button"
                  onClick={() => setSelectedVariant("F")}
                  className={`shadow-lg hover:shadow-primary border ${
                    selectedVariant === "F" && "border-primary"
                  }`}
                >
                  <img
                    src={require("assets/images/products/tania1.jpg")}
                    alt=""
                    className="w-20"
                  />
                  <span className="text-xs">Filles</span>
                </button>
              </div>
            </div>
            <div className="relative grid place-items-center rounded-xl border border-primary mx-6">
              <h3 className="absolute -top-3 left-[calc(50%-50px)] text-sm font-semibold text-center bg-white px-6">
                Total :
              </h3>
              <span className="text-lg font-bold p-3">1450.00 DH</span>
            </div>
          </div> */}
        </div>
        <div className="flex items-center justify-center gap-3 border-t border-primary w-full p-6 mt-6">
          <Button
            isAction
            type="submit"
            // onClick={function (): void {
            //   // something here ...
            //   // props.onClose();
            // }}
          >
            {isLoading ? t("loading") : t("confirm")}
          </Button>
          <Button
            onClick={() => {
              setIsOrderModalOpen(false);
            }}
          >
            {t("close")}
          </Button>
        </div>
      </form>
    </div>
  );
}
