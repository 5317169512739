import "./style.css";
import React, { useContext } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import { ThemeProvider } from "context/ThemeContext";
import { LanguageContext, LanguageProvider } from "context/LanguageContext";
import { AppProvider } from "./context/AppContext";
import { AuthProvider } from "./context/AuthContext";

import HomeLayout from "layouts/HomeLayout";
import ThankyouPage from "views/home/ThankyouPage";

const NotFound = React.lazy(() => import("views/NotFound"));

const Landing = React.lazy(() => import("views/home/Landing"));
const About = React.lazy(() => import("views/home/About"));
const Contact = React.lazy(() => import("views/home/Contact"));


const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="1" />,
      },
      {
        path: ":id",
        element: <Landing />,
      },
      {
        path: ":id/thank-you",
        element: <ThankyouPage />,
      },
      // {
      //   path: "about",
      //   element: <About />,
      // },
      // {
      //   path: "contact",
      //   element: <Contact />,
      // },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default function App() {

  return (
    <ThemeProvider>
      <LanguageProvider>
        <AppProvider>
          <AuthProvider>
            <App_root />
          </AuthProvider>
        </AppProvider>
      </LanguageProvider>
    </ThemeProvider>
  );
}

const App_root = () => {
  const { language } = useContext(LanguageContext);
  return (
    <div
      id="app"
      data-theme="light"
      dir={`${language === "ar" ? "rtl" : "ltr"}`}
    >
      <RouterProvider router={router} />
    </div>
  );
};
