import BestSelling from "components/molecules/BestSelling";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function ThankyouPage() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center text-center">
      <div className="flex flex-col items-center space-y-6 px-10 pt-20">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-28 w-28 text-green-600"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="1"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <h1 className="text-4xl font-bold">{t("thank_you")}</h1>
        <p className="md:max-w-[50%]">{t("thank_you_phrase")}</p>
        <Link
          to="/1"
          className="inline-flex items-center rounded border border-indigo-600 bg-indigo-600 px-4 py-2 text-white hover:bg-indigo-700 focus:outline-none focus:ring"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="mr-2 h-3 w-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M7 16l-4-4m0 0l4-4m-4 4h18"
            />
          </svg>
          <span className="text-sm font-medium mr-2"> {t('back_home')} </span>
        </Link>
      </div>
      <BestSelling />
    </div>
  );
}
