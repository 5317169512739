import { Suspense, useContext } from "react";
import { Outlet } from "react-router-dom";
import Loading from "../components/atoms/animations/LoadingSpin";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import ScrollToTop from "auxilary/ScrollToTop";
import MobileMenu from "components/MobileMenu";
import Modal from "components/ui/Modal";
import Order2 from "views/home/Order2";
import AppContext from "context/AppContext";
import WheelPrize from "components/molecules/WheelPrize";

export default function HomeLayout() {
  const { isOrderModalOpen, showWheelPrize } = useContext(AppContext);
  return (
    <div className="flex flex-col h-full w-full bg-neutral2 text-neutral p-0 md:p-6 font-poppins">
      <MobileMenu />
      <Navbar />
      <div className="grid place-items-center h-full w-full min-h-[calc(100vh-90px)]">
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </div>
      <Footer />
      <ScrollToTop />
      <Modal open={isOrderModalOpen}>
        <Order2 />
      </Modal>
      <Modal open={showWheelPrize}>
        <WheelPrize />
      </Modal>
    </div>
  );
}
