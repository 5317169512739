import { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import LanguageSelector from "./atoms/LanguageSelector";
import ThemeSelector from "./atoms/ThemeSelector";
import { Dropdown } from "flowbite-react";
import AuthContext from "context/AuthContext";
import { useTranslation } from "react-i18next";
import AppContext from "context/AppContext";
import { LanguageContext } from "context/LanguageContext";

export default function Navbar() {
  const { setIsMobileMenuOpen } = useContext(AppContext);
  const { user, logout } = useContext(AuthContext);
  const { t } = useTranslation();
  const {language} = useContext(LanguageContext);

  return (
    <div className="flex items-center justify-between bg-secondary2 shadow-lg px-6 md:px-36 py-2 h-[100px] w-full">
      <div className="hidden md:flex relative items-center border-b-2 border-gray-300 w-[25%]">
        <input
          name="search"
          type="text"
          placeholder={t("search_placeholder")}
          className="border-none w-full bg-gray-100"
        />
        <i className={`absolute ${language==='ar' ? 'left-3':'right-3'}  fa fa-search`}></i>
      </div>
      <Link to="/" className="relative flex items-center gap-3">
        <img
          src={require("assets/images/bambinos-logo.png")}
          alt="logo"
          className="md:h-[70px]"
        />
      </Link>
      <div className="flex items-center justify-end gap-10 w-full md:w-[25%]">
        {/* <button className="hidden md:block relative">
          <i className="fa fa-user text-2xl"></i>
        </button> */}
        {/* <button className="relative">
          <i className="fa fa-heart text-2xl"></i>
          <span className="absolute bg-primary rounded-full h-4 w-4 text-xs text-neutral2 bottom-0 -right-2">
            0
          </span>
        </button> */}
        <button className="hidden md:block relative">
          <i className="fa fa-cart-shopping text-2xl"></i>
          <span className="absolute bg-primary rounded-full h-4 w-4 text-xs text-neutral2 bottom-0 -right-2">
            0
          </span>
        </button>
        <LanguageSelector />
        <button onClick={() => setIsMobileMenuOpen(true)} className="relative">
          <i className="fa fa-bars text-2xl"></i>
        </button>
        {/* <div className="flex items-center gap-6">
          {menu.map((item, index) => {
            return (
              <NavLink
                key={index}
                to={item.link}
                className={`font-semibold text-md border-white duration-75 ${
                  item?.isAction
                    ? "bg-gradient-to-br from-primary1 to-primary2 text-neutral2 rounded-lg shadow-lg px-6 py-2"
                    : "py-1 border-b-[3px] hover:border-primary hover:text-primary"
                }`}
              >
                {item.name}
              </NavLink>
            );
          })}
        </div> */}
      </div>
    </div>
  );
}
